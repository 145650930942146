import Router from "next/router";
import { ParsedUrlQuery } from "querystring";
import { ChartRelease } from "../../chart.models";

export function handleRouterQueries(chartPath: string, chartRelease: ChartRelease, query: ParsedUrlQuery) {
	if (query && "date" in query) {
		delete query["date"];
	}
	Router.push({
		pathname:`${chartPath}/${chartRelease.end_date}`,
		query,
	}, undefined, { shallow: true });
}