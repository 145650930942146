import styles from "./info-header.module.scss";
import Image from "next/image";
import { EMPTY_ALT_ATTRIBUTE } from "../../chart.constants";
import { ChartDropdownItem, ChartID, ChartRelease } from "../../chart.models";
import Icon from "../../assets/icons/icons";
import ShareButton from "../share-button/share-button.component";
import SubscribeModal from "../subscribe-modal/subscribe-modal.component";
import DateDropdown from "../date-dropdown/date-dropdown.component";

interface chartInfoProps {
	currentChartRelease: ChartRelease
	name: string
	description: string
	art: string
	id: ChartID
	currentWeekOfText: string
	allChartReleases: Array<ChartDropdownItem>
}

function InfoHeader(props: chartInfoProps) {
	return (
		<div className={styles.info_header}>
			<div className={styles.image}>
				<Image
					alt={EMPTY_ALT_ATTRIBUTE}
					src={props.art}
					width={293}
					height={293}
					unoptimized
				/>
			</div>
			<div className={styles.header}>
				<h2 className={styles.title}>{props.name}</h2>
				<span className={styles.subtitle}>
					{props.description}
				</span>
			</div>
			<div className={styles.body}>
				<header className={styles.date}>
					<DateDropdown
						chartRelease={props.currentChartRelease}
						chartID={props.id}
						weekOfText={props.currentWeekOfText}
						allChartReleaseDates={props.allChartReleases}
					/>
				</header>
				<div className={styles.actions}>
					<a href={props.currentChartRelease.listening_link} target="_blank" rel="noreferrer" className={styles.play_button}>
						<Icon icon="play" size={22} title="play" />
						{props.allChartReleases[0].value === props.currentChartRelease.end_date ? "Play on Pandora" : "Play Today's Chart" }
					</a>
					<SubscribeModal
						name={props.name}
						chartId={props.id}
					/>
					<ShareButton
						shareLink={props.currentChartRelease.share_link}
					/>
				</div>
			</div>
		</div>
	);
}

export default InfoHeader;