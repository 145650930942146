import Head from "next/head";
import moment from "moment";
import Chart from "../components/chart/chart.component";
import InfoHeader from "../components/info-header/info-header.component";
import HighlightReel from "./highlight-reel/highlight-reel.component";
import { ChartRelease, ChartID, ChartDropdownItem } from "../chart.models";
import { usePageLoading } from "../services/server/page-loading.service";

import styles from "./chart-page.module.scss";
import { BadgeTooltips, Charts } from "../chart.constants";
import ReactTooltip from "react-tooltip";
import { useRef, useState } from "react";

const ChartPage: React.FC<{
	chartRelease?: ChartRelease,
	name: string,
	metaTitle: string,
	description: string,
	art: string,
	chartID: ChartID
	weekOfText: string
	allChartReleases: Array<ChartDropdownItem>
}> = ({chartRelease, name, metaTitle, description, art, chartID, weekOfText, allChartReleases}) => {
	const { isPageLoading } = usePageLoading();
	const [tooltipText, setTooltipText] = useState("");
	const chartPageRef = useRef() as React.MutableRefObject<HTMLInputElement>;

	if (chartRelease) {
		const weekOf = moment(chartRelease.start_date).format("MMMM DD, YYYY");
		const isTrendsettersChart = chartID === Charts.Trendsetters.id;

		const showBadgeTooltip = (event: Event | undefined) => {
			if (chartPageRef.current && event) {
				const selectedBadge = event.target as HTMLInputElement;
				const isBadge = selectedBadge.classList.contains("badge");
				ReactTooltip.rebuild();
				if (isBadge) {
					if (BadgeTooltips[selectedBadge.innerText]) setTooltipText(BadgeTooltips[selectedBadge.innerText].text);
				}
			}
		};

		return (
			<span ref={chartPageRef} onMouseOver={() => showBadgeTooltip(event)}>
			  <Head>
					<meta property="og:title" content={metaTitle}></meta>
					<meta property="og:description" content={description}></meta>
					<meta property="og:image" content={art}></meta>
					<meta name="twitter:label1" content="For The Week Of"></meta>
					<meta name="twitter:data1" content={weekOf}></meta>
				</Head>
				<div className={styles.info_header}>
					<InfoHeader
						currentChartRelease={chartRelease}
						name={name}
						description={description}
						art={art}
						id={chartID}
						currentWeekOfText={weekOfText}
						allChartReleases={allChartReleases}
					/>
				</div>
				<div className={styles.highlight_reel}>
					<HighlightReel
						highlightReel={chartRelease.highlight_reel}
						isTrendsettersChart={chartID === Charts.Trendsetters.id}
					/>
				</div>
				<div className={styles.chart}>
					{isPageLoading ? <div className={styles.loading}>
						<span className={styles.loading_copy}>loading...</span>
					</div> : <Chart
						chartRelease={chartRelease}
						name={name}
						id={chartID}
					/>}

				</div>
				<ReactTooltip
					id={"badge_tooltip"}
					place="bottom"
					effect="solid"
					class={styles.tooltip}
				>
					{isTrendsettersChart ? tooltipText.replace("track", "artist") : tooltipText}
				</ReactTooltip>
			</span>
		);
	} else {
		return (
			<div>This chart is currently unavailable.</div>
		);
	}
};

export default ChartPage;
