import { ReactNode, MouseEventHandler } from "react";
import cn from "clsx";

import styles from "./button.module.scss";

export type ButtonWidth = "default-width" | "full-width";

export interface ButtonProps {
	type?: "button" | "submit";
	styling?: "primary" | "secondary" | "transparent";
	width?: ButtonWidth;
	image?: ReactNode;
	allowImageOnly?: boolean;	// The button will ignore text and only show an image on smaller devices
	title?: string;
	disabled?: boolean;
	onClick?: MouseEventHandler<HTMLButtonElement>;
	children?: ReactNode;
}

export function Button ({ width = "default-width", styling = "primary", image, allowImageOnly = false, title, disabled, onClick, children }: ButtonProps) {
	const className = cn(
		styles.Button,
		styles[styling],
		styles[`Button__${width}`],
		image && !children && styles["image-only"],
		allowImageOnly && styles["allow-image-only"],
		disabled && styles.disabled
	);
	const textClassName = cn(styles.text);

	return (
		<button onClick={onClick} className={className} title={title} disabled={disabled}>
			{image && <div className={styles.image}>{image}</div>}
			{children && <div className={textClassName}>{children}</div>}
		</button>
	);
}

export default Button;