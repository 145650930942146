import { ChartID } from "../../chart.models";
import fetch from "node-fetch";

export function subscribeToChartEmail(chartID: ChartID, email: string, baseUrl?: string): Promise<boolean> {
	// this request will go through the next.js api. We allow the baseUrl for testing purposes.

	let url = `/subscribe/${chartID}`;
	if(baseUrl) url = baseUrl + url;
	else url = "/api" + url;
	const data = {email};

	return fetch(url, {
		method: "POST",
		headers: {
			"Accept": "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify(data),
	})
		.then(response => {
			if (response.status === 200) return true;
			else if (response.status === 409) return true;
			else return false;
		})
		.catch(() => {
			return false;
		});
}