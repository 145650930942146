import styles from "./share-button.module.scss";
import Icon from "../../assets/icons/icons";
import ReactTooltip from "react-tooltip";
import { useEffect, useState } from "react";

const BREAKPOINT = 768;

interface shareButtonProps {
	id?: number
	shareLink: string
}

function ShareButton(props: shareButtonProps) {
	const [isCopied, setIsCopied] = useState(false);
	const [isMounted, setIsMounted] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const [tooltipText, setTooltipText] = useState("");

	useEffect(() => {
		setIsMounted(true);

		if (!window.matchMedia) {
			return;
		}

		const mediaQuery = window.matchMedia(`(max-width: ${BREAKPOINT}px)`);
		if (!mediaQuery) {
			return;
		}

		setIsMobile(mediaQuery.matches);
		setTooltipText(mediaQuery.matches ? "Copied!" : "Copy Link");
	}, []);

	function updateClipboard(newClip: string) {
		navigator.clipboard.writeText(newClip).then(function() {
			setIsCopied(true);
			setTooltipText("Copied!");
			setTimeout(() => {
				setIsCopied(false);
				setTooltipText("Copy Link");
			}, 1000);
		}, function() {
			setIsCopied(false);
			setTooltipText("Link failed to copy");
			setTimeout(() => {
				setTooltipText("Copy Link");
			}, 1000);
		});
	}

	return (
		<>
			<button onClick={() => updateClipboard(props.shareLink)} className={isCopied ? styles.success : styles.share_button} data-tip data-for={"copyTooltip" + props.id}>
				<Icon icon="link" size={22} />
				<div className={styles.text}>{isMobile ? "Copied!" : "Copy link"}</div>
			</button>

			{isMounted &&
				<ReactTooltip
					id={"copyTooltip" + props.id}
					place="top"
					effect="solid"
					type="light"
					afterShow={() => { if(isMobile) setTimeout(ReactTooltip.hide, 1000); }}
					className={styles.tooltip}
				>
					{tooltipText}
				</ReactTooltip>
			}
		</>
	);
}

export default ShareButton;