import { Badges } from "../../chart.constants";
import styles from "./badge.module.scss";

interface badgeProps {
	id: number,
	isTrendsettersChart: boolean,
	isHighlightBadge: boolean
}

function Badge(props: badgeProps) {
	return (
		<>
			<div data-tip data-for="badge_tooltip"
				className={(props.isHighlightBadge ? styles.highlight_badge : styles.container) + " " + (Badges[props.id].emoji === "🔁" ? styles.comeback_fallback : styles[Badges[props.id].class]) + " " + styles[Badges[props.id].size] + " badge"}
			>
				<span className={styles.emoji}>
					{Badges[props.id].emoji}
				</span>
				<span className={styles.name}>{Badges[props.id].name}</span>
			</div>
		</>
	);
}

export default Badge;