import styles from "./date-dropdown.module.scss";
import { Chart, ChartDropdownItem, ChartRelease } from "../../chart.models";
import { Charts } from "../../chart.constants";
import moment from "moment";
import Icon from "../../assets/icons/icons";
import React from "react";
import Select, { components, DropdownIndicatorProps, SingleValue } from "react-select";
import { useRouter } from "next/router";

interface dateDropdownProps {
	chartRelease: ChartRelease
	chartID: string
	weekOfText: string
	allChartReleaseDates: Array<ChartDropdownItem>
}

function DateDropdown(props: dateDropdownProps) {

	const router = useRouter();

	const ChangeChart = (item: SingleValue<ChartDropdownItem>) => {
		const chart = Object.values(Charts).find((chart: Chart) => chart.id === props.chartID);
		router.push(chart?.path + "/" + item?.value);
	};

	const DropdownIndicator = (props: DropdownIndicatorProps<ChartDropdownItem, false>) => {
		return (
			components.DropdownIndicator && (
				<components.DropdownIndicator {...props}>
					<Icon icon="chevron" size={16} title="chevron" className={[styles.chevron, props.selectProps.menuIsOpen ? styles.upside_down : ""].join(" ")}/>
		 		</components.DropdownIndicator>
			)
		);
	};

	return (
		<div className={styles.date}>
			<span className={styles.week_of}>
				<span className={styles.week_of}>{props.weekOfText}</span>
				<Select<ChartDropdownItem, false>
					classNamePrefix="react-select"
					components={{ DropdownIndicator, IndicatorSeparator: () => null }}
					options={props.allChartReleaseDates}
					placeholder={moment(props.chartRelease.end_date).format("MMMM DD, YYYY")}
					isSearchable={false}
					onChange={ChangeChart}
					instanceId={props.chartRelease.id}
					value={props.allChartReleaseDates.find(option => option.value === props.chartRelease.end_date)}
				/>
			</span>
			<span className={styles.date_range}>{moment(props.chartRelease.start_date).format("ddd MMM DD")} — {moment(props.chartRelease.end_date).format("ddd MMM DD, YYYY")}</span>
		</div>
	);
}

export default DateDropdown;