import Icon from "../../assets/icons/icons";

import styles from "./rank-change.module.scss";

interface rankChangeProps {
 	rank: number
	previousRank: number | null
	weeksOnChart: number
}

const RankChange: React.FunctionComponent<rankChangeProps> = props => (
	<div className={styles.rank_change}>
		{isBetterRank(props.rank, props.previousRank) && <Icon icon="arrow" size='100%' title="better rank" className={styles.arrow} />}
		{isSameRank(props.rank, props.previousRank) && <Icon icon="arrow" size='100%' title="same rank" className={styles.arrowRight} />}
		{isWorseRank(props.rank, props.previousRank) && !isNew(props.weeksOnChart) && <Icon icon="arrow" size='100%' title="worse rank" className={styles.arrowDown} />}
		{isBetterRank(props.rank, props.previousRank) && <span className={styles.rank}>{!props.previousRank ? "" : props.previousRank - props.rank}</span>}
		{isWorseRank(props.rank, props.previousRank) && !isNew(props.weeksOnChart) && <span className={styles.rank}>{!props.previousRank ? "" : props.rank - props.previousRank}</span>}
	</div>
);

function isSameRank(currentRank: number, previousRank: number | null): boolean {
	return currentRank == previousRank;
}

function isBetterRank(currentRank: number, previousRank: number | null): boolean {
	return !previousRank ? false : currentRank < previousRank;
}

function isWorseRank(currentRank: number, previousRank: number | null): boolean {
	return !previousRank ? false : previousRank < currentRank;
}

function isNew(weeksOnChart: number): boolean {
	return weeksOnChart == 1;
}

export default RankChange;