import { Highlight } from "../../chart.models";
import Hightlight from "../highlight/highlight.component";
import styles from "./highlight-reel.module.scss";

interface highlightReelProps {
	highlightReel: Array<Highlight>
	isTrendsettersChart: boolean
}

function HighlightReel(props: highlightReelProps) {
	return (
		<>
			<h3 className={styles.title}>Pandora Highlights</h3>
			<div className={styles.container}>
				{
					props.highlightReel.map((highlight) =>
						<Hightlight key={highlight.badge}
							badge={highlight.badge}
							imageUrl={highlight.image_url}
							artistName={highlight.artist_name}
							trackName={highlight.track_name}
							listeningLink={highlight.listening_link}
							shareLink={highlight.share_link}
							isTrendsettersChart={props.isTrendsettersChart}
						/>)
				}
			</div>
		</>
	);
}

export default HighlightReel;