import { ImageLoader } from "next/image";

// determine the next largest size precomputed by mediamanager
//    https://wiki.savagebeast.com/display/CONTSERV/Media+Manager%3A+Input+and+Output+Formats#MediaManager:InputandOutputFormats-Defaultimageformats
function mediaManagerSize(width: number): number {
	if (width <= 90) return 90;
	if (width <= 130) return 130;
	if (width <= 250) return 250;
	if (width <= 500) return 500;
	if (width <= 640) return 640;
	if (width <= 1080) return 1080;
	return 1980;
}

const mediaManagerRegex = new RegExp("[0-9]+W_[0-9]+H.jpg");

// see https://nextjs.org/docs/api-reference/next/image#loader
const loader: ImageLoader = ({ src, width }) => {
	return createCdnSrc(src, width);
};

export function createCdnSrc(src: string, width: number): string {
	const widthToUse = mediaManagerSize(width);
	const sizeToUse = `${widthToUse}W_${widthToUse}H.jpg`;
	return src.replace(mediaManagerRegex, sizeToUse);
}

export default loader;
