import styles from "./subscribe-modal.module.scss";
import Modal from "react-modal";
import { useState } from "react";
import Icon from "../../assets/icons/icons";
import { subscribeToChartEmail } from "../../services/client/subscribe.service";
import { ChartID } from "../../chart.models";
import Button from "../button/button.component";

interface subscribeModalProps {
	name: string
	chartId: ChartID
}

function SubscribeModal(props: subscribeModalProps) {
	const [modalIsOpen, setIsOpen] = useState(false);
	const [wasSubscribed, setWasSubscribed] = useState(false);
	const [wasSubmitted, setWasSubmitted] = useState(false);
	const [email, setEmail] = useState("");
	const [isValidEmail, setIsValidEmail] = useState(false);

	function openModal() {
		setIsOpen(true);
	}

	function closeModal() {
		setEmail("");
		setWasSubscribed(false);
		setWasSubmitted(false);
		setIsOpen(false);
		setIsValidEmail(false);
	}

	function handleUserEmail(event: React.ChangeEvent<HTMLInputElement>) {
		const emailInput = event.target;
		setIsValidEmail(emailInput.checkValidity());
		setEmail(event.target.value);
		if (wasSubmitted) setWasSubmitted(false);
	}

	async function onSubmit(event: React.SyntheticEvent) {
		event.preventDefault();
		return await subscribeToChartEmail(props.chartId, email)
			.then(response => {
				setWasSubscribed(response);
				setWasSubmitted(true);
			});
	}

	return (
		<>
			<Button styling='secondary' onClick={openModal} image={<Icon icon="bell" size={22} />} allowImageOnly={true} title="subscribe">Subscribe</Button>
			<Modal
				closeTimeoutMS={500}
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel="Subscribe Modal"
				ariaHideApp={false}
				className={styles.modal}
				overlayClassName={{
					base: styles.overlay,
					afterOpen: styles.overlay_after_open,
					beforeClose: styles.overlay_before_close
				  }}
			>
				<div className={styles.modal_container}>
					<div className={styles.heading_container}>
						<h2 className={styles.heading}>Subscribe to Charts</h2>
						<Button styling="transparent" onClick={closeModal} image={<Icon icon="close" size={20} className={styles.close_icon} />} title="close"/>
					</div>
					<form className={styles.form} onSubmit={onSubmit}>
						<input
							name="email"
							placeholder="you@email.com"
							type="email"
							autoFocus={true}
							className={styles.email}
							onChange={(event) => handleUserEmail(event)}
							value={email}
							required={true}
						/>
						<Button type="submit" width="full-width" disabled={!isValidEmail}>Sign up</Button>
						<span className={styles.errorSuccessMessage + " " + (wasSubscribed ? styles.successMessage : styles.errorMessage)}>
							{wasSubmitted ? wasSubscribed ? email + " was successfully subscribed to " + props.name + " Chart emails!" : "An error occurred. Please try again later." : ""}
						</span>
					</form>
				</div>
			</Modal>
		</>
	);
}

export default SubscribeModal;