import Image from "next/image";
import Badge from "../badge/badge.component";
import ShareButton from "../share-button/share-button.component";
import styles from "./hightlight.module.scss";

interface hightlightProps {
	badge: number,
	imageUrl: string,
	artistName: string,
	trackName: string,
	listeningLink: string,
	shareLink: string,
    isTrendsettersChart: boolean
}

function Hightlight(props: hightlightProps) {
	return (
		<div className={styles.card}>
			<a href={props.listeningLink} target="_blank" rel="noreferrer" className={styles.listening_link}>
				<div className={styles.image_crop}>
					<Image
						alt={props.isTrendsettersChart ? "Artist image" : "Album artwork"}
						src={props.imageUrl}
						width={182}
						height={182}
						className={styles.image}
					/>
				</div>
			</a>
			<div className={styles.card_info}>
				<span className={styles.buttons}>
					<span className={styles.badge}>
						<Badge
							id={props.badge}
							isTrendsettersChart={props.isTrendsettersChart}
							isHighlightBadge={true}
						/>
					</span>
					<span className={styles.share}>
						<ShareButton
							id={props.badge}
							shareLink={props.shareLink}
						/>
					</span>
				</span>
				<div className={styles.text_container}>
					<span className={styles.track_name}>{props.trackName ? props.trackName : props.artistName}</span>
					{props.trackName && <span className={styles.artist_name}>{props.artistName}</span>}
				</div>
			</div>
		</div>
	);
}

export default Hightlight;