import styles from "./chart.module.scss";
import Image from "next/image";
import cn from "clsx";

import { ChartID, ChartPosition, ChartRelease } from "../../chart.models";
import { Charts } from "../../chart.constants";

import RankChange from "../rank-change/rank-change.component";
import pandoraCdnLoader from "../../services/server/pandora-cdn.service";
import Badge from "../badge/badge.component";

interface chartProps {
	chartRelease: ChartRelease
	name: string
	id: ChartID
}

const Chart: React.FunctionComponent<chartProps> = props => {
	const isTrendsettersChart = props.id === Charts.Trendsetters.id;

	return (
		<>
			<table className={styles.chart}>
				<colgroup>
					<col className={styles.column__rank} />
					<col className={styles.column__image} />
					<col className={styles.column__track} />
					<col className={styles.column__badges} />
					<col className={styles.column__weeks} />
				</colgroup>
				<thead>
					<tr className={styles.header_row}>
						<th className={styles.header_column__rank}>Rank</th>
						<th className={styles.header_column__image}>{props.id != Charts.Trendsetters.id ? "Track" : "Artist"}</th>
						<th className={styles.header_column__track}>{props.id != Charts.Trendsetters.id ? "Track" : "Artist"}</th>
						<th className={styles.header_column__badges}></th>
						<th className={styles.header_column__weeks}>Weeks on Chart</th>
					</tr>
				</thead>
				<tbody className={styles.chart_body}>
					{
						props.chartRelease.chart_positions.map((cp: ChartPosition) =>
							<tr key={cp.pandora_id} className={styles.body_row}>
								<td className={styles.column__rank}>
									<div className={styles.column_link__rank}>
										<div className={styles.rank_number}>{cp.rank}</div>
										{cp.rank && <div className={styles.rank_change}><RankChange rank={cp.rank} previousRank={cp.last_week_rank} weeksOnChart={cp.weeks_on_chart}/></div>}
									</div>
								</td>
								<td className={styles.column__image}>
									<a href={cp.listening_link} target="_blank" rel="noreferrer">
										<Image
											alt={isTrendsettersChart ? "Artist image" : "Album artwork"}
											src={cp.image_url}
											width={62}
											height={62}
											className={cn(styles.image, props.id === Charts.Trendsetters.id ? styles.image__circle : styles.image__square)}
											loader={pandoraCdnLoader}
										/>
									</a>
								</td>
								<td className={styles.column__track}>
									<a href={cp.listening_link} target="_blank" rel="noreferrer" className={styles.source_name}>
										<span className={styles.track_name}>{isTrendsettersChart ? cp.artist_name : cp.track_name}</span>
										{!isTrendsettersChart && <div className={styles.artist_name}>{cp.artist_name}</div>}
									</a>
								</td>
								<td className={styles.column__badges}>
									<div className={styles.container_badges}>
										{
											cp.badges.map(badge => <Badge key={badge} id={badge} isTrendsettersChart={isTrendsettersChart} isHighlightBadge={false}/>)
										}
									</div>
								</td>
								<td className={styles.column__weeks}>
									<div className={styles.weeks}>{cp.weeks_on_chart}</div>
								</td>
							</tr>
						)
					}
				</tbody>
			</table>
		</>
	);
};

export default Chart;